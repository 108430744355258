import { IAppState } from "../constants/interface";
import { initialAppState } from "./state";

const stateName = 'appLocalState';
export const saveToLocalStorage = (state: IAppState) => {
  try {
    localStorage.setItem(stateName, JSON.stringify(state));
  } catch (e) {
    console.error(e);
  }
};

export const loadFromLocalStorage = (): IAppState => {
  try {
    const stateStr = localStorage.getItem(stateName);
    let stateObj: IAppState;
    if (stateStr) {
      stateObj = JSON.parse(stateStr);
      console.log("loadFromLocalStorage");
      return stateObj;
    }
    return initialAppState;
  } catch (e) {
    console.error(e);
    return initialAppState;
  }
};
