import { IAddNewPictures, IAddOnePicture, IAppState, IEditOnePicture, IPicture, IDelOnePicture } from './../constants/interface';
import { AppActions, ActionType } from './actions';

export const appReducer = (state: IAppState, action: AppActions): IAppState => {
  switch (action.type) {
    case ActionType.AddNewPictures:
      return _addNewPictures(action, state);
    case ActionType.EditOnePicture:
      return _editOnePicture(action, state);
    case ActionType.AddOnePicture:
      return _addOnePicture(action, state);
    case ActionType.DelOnePicture:
      return _delOnePicture(action, state);
    default:
      return state;
  }
};

// helper functions to simplify the caller
export const addNewPictures = (pictures: IPicture[]): IAddNewPictures => ({
  type: ActionType.AddNewPictures,
  payload: pictures,
});


const _addNewPictures = (action: IAddNewPictures, state: IAppState) => {
  let allPictures: IPicture[] = [...action.payload];
  state.pictures.map((oldPic) => {
    const newPic = allPictures.find((item) => item.id === oldPic.id);
    if (!newPic) {
      allPictures.push(oldPic);
    }
    return allPictures;
  });

  console.log(
    "_addNewPictures action - state -allPictures",
    action,
    state,
    allPictures
  );
  return { ...state, pictures: allPictures };
};

export const editOnePicture = (picture: IPicture): IEditOnePicture => ({
  type: ActionType.EditOnePicture,
  payload: picture,
});

const _editOnePicture = (action: IEditOnePicture, state: IAppState) => {
  const newPic: IPicture = action.payload;

  console.log("_editOnePicture action - state -newPic", action, state, newPic);
  return {
    ...state,
    pictures: [newPic, ...state.pictures.filter((pic) => pic.id !== newPic.id)],
  };
};


export const addOnePicture = (picture: IPicture): IAddOnePicture => ({
  type: ActionType.AddOnePicture,
  payload: picture,
});

const _addOnePicture = (action: IAddOnePicture, state: IAppState) => {
  const newPic: IPicture = action.payload;

  console.log(
    "_addOnePicture Picture action - state -newPic",
    action,
    state,
    newPic
  );
  return {
    ...state,
    pictures: [newPic, ...state.pictures],
  };
};

export const delOnePicture = (picture: IPicture): IDelOnePicture => ({
  type: ActionType.DelOnePicture,
  payload: picture,
});

const _delOnePicture = (action: IDelOnePicture, state: IAppState) => {
  const newPic: IPicture = action.payload;

  return {
    ...state,
    pictures: [...state.pictures.filter((pic) => pic.id !== newPic.id)],
  };
};