import { IAddNewPictures, IAddOnePicture, IDelOnePicture, IEditOnePicture } from './../constants/interface';
export enum ActionType {
  AddNewPictures,
  AddOnePicture,
  EditOnePicture,
  DelOnePicture,
}

export type AppActions =
  | IAddNewPictures
  | IAddOnePicture
  | IEditOnePicture
  | IDelOnePicture;

