import { useLazyQuery, useQuery } from "@apollo/client";
import React, { Suspense, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GET_ALL_PICTURES, GET_AUTO_UID_POINT } from "../constants/graphqlQueries";
import { IPicture } from "../constants/interface";
import {
  AWARD_NUM_PER_POINT,
  DAILY_FREE_NUM,
  FIRST_DAY_FREE_NUM,
  LIST_PICTURE_PAGE_SIZE
} from "../constants/settings";
import { autoUidTimeDiff, myLog } from "../helpers/function";
import { AppContext } from "../store";
import { addNewPictures } from "../store/reducer";
import { ListPictureCard } from "./ListPictureCard";
import { AddNewPicLink } from "./ListPictureLinks";
import { ListPictureLoadMore } from "./ListPictureLoadMore";
import { ListPictureSingle } from "./ListPictureSingle";
import { ReachedLimit } from "./ReachedLimit";

const CreateOrUpdatePoint = React.lazy(() => import("./CreateOrUpdatePoint"));

export const ListPictures = (): JSX.Element => {
  const { contextDispatch, contextState } = useContext(AppContext);
  const [pageNum, setPageNum] = useState(1);
  const [reachedLimit, setReachedLimit] = useState(false);
  const params = useParams();
  const [hasMoreData, setHasMoreData] = useState(true);

  const [getAutoUidPoint, autoUidPoint] = useLazyQuery(GET_AUTO_UID_POINT);

  useEffect(() => {
    setTimeout(() => {
      if (reachedLimit) {
        getAutoUidPoint({
          variables: {
            filters: { auto_uid: { eq: contextState.AutoUID } },
          },
        });
      }
    }, 5000);
  }, [reachedLimit]);



  const allPictures = useQuery(GET_ALL_PICTURES, {
    // fetchPolicy: "cache-and-network",
    fetchPolicy: "cache-first",
    variables: { page: pageNum, pageSize: LIST_PICTURE_PAGE_SIZE },
    onCompleted(res) {
      // myLog("title:ListPictures useQuery onCompleted", res, "color:pink");
      contextDispatch(addNewPictures(res.pictures.data));
      if (res.pictures.data.length === 0) {
        setHasMoreData(false);
      }
    },
  });

  const handleLoadMore = ():void => setPageNum(pageNum + 1);

  const awardPointNum = autoUidPoint.called && autoUidPoint.data?.points.data.length > 0
    ? AWARD_NUM_PER_POINT * (autoUidPoint.data.points.data[0].attributes.point || 0)
    : 0;
  const diffDays = autoUidTimeDiff(contextState.AutoUID,'days') + 1;
  const allowedNum =
    FIRST_DAY_FREE_NUM + DAILY_FREE_NUM * (diffDays - 1) + awardPointNum;
  
  if (contextState.pictures.length >= allowedNum && !reachedLimit) {
    setReachedLimit(true);
  } else if (contextState.pictures.length < allowedNum && reachedLimit) {
    setReachedLimit(false);
  }

  let picturesToDisplay = contextState.pictures
    .sort((a, b) => parseInt(b.id || "") - parseInt(a.id || ""))
    .slice(0, pageNum * LIST_PICTURE_PAGE_SIZE);

  if (params.picID) {
    picturesToDisplay = [
      ...picturesToDisplay.filter((p) => p.id !== params.picID),
    ];
  }


  myLog(
    "title:reachedLimit",
    awardPointNum,
    allowedNum,
    contextState.pictures.length,
    autoUidPoint,
    "color:pink"
  );

  return (
    <div className="row justify-content-md-center">
      <div className="col-md-12 text-center mb-1">
        <AddNewPicLink />
      </div>

      {params.picID && (
        <div className="col-md-6  d-flex justify-content-center">
          <ListPictureSingle picId={params.picID} />
        </div>
      )}

      {picturesToDisplay.map((pic: IPicture, i: number) => (
        <div className="col-md-6  d-flex justify-content-center" key={pic.id}>
          <ListPictureCard pic={pic} />
        </div>
      ))}

      {reachedLimit ? (
        <div className="col-md-12 text-center mb-2">
          <ReachedLimit allowedNum={allowedNum} recheckPoint={getAutoUidPoint} />
        </div>
      ) : (
        <div className="col-md-12 text-center mb-2">
          <ListPictureLoadMore
            loading={allPictures.loading}
            hasMoreData={hasMoreData}
            handleLoadMore={handleLoadMore}
          />
        </div>
      )}

      {params.autoUID && autoUidTimeDiff(contextState.AutoUID, "seconds") < 9 && (
        <Suspense fallback={<div>Updating point...</div>}>
          <CreateOrUpdatePoint referrerAutoUid={params.autoUID} />
        </Suspense>
      )}

      <div className="col-md-12 text-center mb-5">
        <AddNewPicLink />
      </div>
    </div>
  );
};
