import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { ADD_PICTURE } from "../constants/graphqlQueries";
import { render } from "react-dom";

export const MyPicture = (): JSX.Element => {


  return (
    <div className="row justify-content-md-center">
      <div className="col-md-3">my pictures</div>
    </div>
  );
};
