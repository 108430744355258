import { IPicture } from "../constants/interface";
import { ListPictureLinks } from "./ListPictureLinks";

interface IProps {
  pic: IPicture;
};

export const ListPictureCard = ({ pic }: IProps): JSX.Element => {
  const newImgUrl = pic.attributes.url.includes("imagekit.io")
    ? pic.attributes.url + "?tr=w-600,fo-auto,f-webp"
    : pic.attributes.url;
  return (
    <div className="card mb-4" style={{ width: "350px" }}>
      <img
        className="card-img"
        src={newImgUrl}
        alt={pic.attributes.title}
        loading="lazy"
      />
      <div className="card-body">
        <h5 className="card-title">{pic.attributes.title}</h5>
        <p className="card-text">{pic.attributes.description}</p>

        <ListPictureLinks id={pic.id || ""} />
      </div>
    </div>
  );
};

export default ListPictureCard;
