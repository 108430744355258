import moment from "moment";

export const myLog = (...args: any) => {
  let color: string = "";
  let title: string = "";
  let newArgs: any[] = [];
  args.map((arg: any) => {
    if (typeof arg === "string" && arg.includes("title:")) {
      title = arg;
    } else if (typeof arg === "string" && arg.includes("color:")) {
      color = arg;
    } else {
      newArgs.push(arg);
    }
    return true;
  });
  if (title !== "") {
    if (color !== "") {
      console.log("%c============ " + title + " ============", color);
    } else {
      console.log("============ " + title + " ============");
    }
  }

  newArgs.map((arg: any) => {
    if (typeof arg === "string" && color !== "") {
      console.log("%c" + arg, color);
    } else {
      console.log(arg);
    }

    return false;
  });
};

export const generateAutoUid = (): string => {
  return (
    moment().format("YYYYMMDDHmmss") + (Math.floor(Math.random() * 9000) + 1000)
  );
}
export const getAutoUidMoment = (AutoUID: string): moment.Moment => {
  return moment(AutoUID.slice(0, -4), "YYYYMMDDHmmss");
};

export const autoUidTimeDiff = (
  AutoUID: string,
  unit: moment.unitOfTime.Diff = "days",
  compareMoment: moment.MomentInput = undefined
): number => {
  return moment(compareMoment).diff(getAutoUidMoment(AutoUID), unit);
};