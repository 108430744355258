import React, { useContext, useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { EDIT_PICTURE } from "../constants/graphqlQueries";
import { IPicture } from "../constants/interface";
import { Link, Navigate, useParams } from "react-router-dom";
import {
  GET_ALL_PICTURES,
  GET_ONE_PICTURE,
  DEL_ONE_PICTURE,
} from "../constants/graphqlQueries";
import { initialPicture } from "../store/state";
import { AppContext } from "../store";
import { editOnePicture } from "../store/reducer";


export const EditPicture = (): JSX.Element => {
  const params = useParams();
  const picId = params.id || "";
  const { contextDispatch, contextState } = useContext(AppContext);

  const oldPic = useQuery(GET_ONE_PICTURE, { variables: { id: picId } });

  // const [
  //   getOnePic,
  //   { loading: pdLoading, error: pdError, data: pictureDetail },
  // ] = useLazyQuery(GET_ONE_PICTURE);

  const [updatePicture, updatedPic] = useMutation(EDIT_PICTURE);
  const [newPic, setNewPic] = useState<IPicture>(initialPicture);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputData = {
      [event.target.name]: event.target.value,
    };
    const picFormData = {
      id: picId,
      attributes: { ...newPic.attributes, ...inputData },
    };
    console.log(
      "===================handleOnChange picFormData inputData================="
    );
    console.log(picFormData, inputData);

    setNewPic(picFormData);
  };
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const newData = {
      id: picId,
      title: newPic.attributes.title,
      url: newPic.attributes.url,
      tags: newPic.attributes.tags,
    };

    updatePicture({ variables: newData });
    contextDispatch(editOnePicture(newPic));

    console.log("===================EditPicture handleSubmit=================");
    console.log(newPic, newData);
    console.log("====================================");
  };

  useEffect(() => {
    if (oldPic.data) setNewPic(oldPic.data.picture.data);
  }, [oldPic.data]);

  if (
    // !newPic ||
    // !newPic.attributes ||
    newPic.attributes.title === ""
  ) {
    console.log("pictureDetail", oldPic, newPic);
    return <></>;
  }

  if (oldPic.loading) return <p>Loading...</p>;
  if (oldPic.error) return <p>Error :( {oldPic.error.message}</p>;

  console.log(
    "===================EditPicture render newPic - oldPic ================="
  );
  console.log(newPic, oldPic.data.picture.data, updatedPic);

  return (
    <div className="row justify-content-md-center">
      {updatedPic.data && !updatedPic.error && (
        <Navigate to="/" replace={true} />
      )}

      <div className="col-md d-flex justify-content-center">
        <div className="card mb-4" style={{ width: "350px" }}>
          <img
            className="card-img"
            src={newPic.attributes.url}
            alt={newPic.attributes.title}
          />

          <form onSubmit={handleSubmit}>
            <label>
              Edit Title:
              <input
                onChange={handleOnChange}
                type="text"
                name="title"
                value={newPic.attributes.title}
              />
            </label>
            <label>
              URL:
              <input
                onChange={handleOnChange}
                type="text"
                name="url"
                value={newPic.attributes.url}
              />
            </label>
            <label>
              Tags:
              <input
                onChange={handleOnChange}
                type="text"
                name="tags"
                value={newPic.attributes.tags || ""}
              />
            </label>

            {updatedPic.error && (
              <div>Submission error! {updatedPic.error.message}</div>
            )}

            {updatedPic.loading ? (
              <div>Submitting...</div>
            ) : (
              <div>{/* <input type="submit" value="Add" /> */} no loading</div>
            )}
            <input type="submit" value="Edit" />
            {updatedPic.data && <div>Submission success! </div>}
          </form>
        </div>
      </div>

      <div className="col-md-12 text-center mb-5">
        <Link
          to={{
            pathname: "/",
            search: "?from=addPicture",
            hash: "#the-hash",
          }}
        >
          <i className="fas fa-list m-3"></i>Back to list
        </Link>
      </div>
    </div>
  );
};
