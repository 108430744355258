import { generateAutoUid } from '../helpers/function';
import { IAppState, IPicture } from './../constants/interface';

export const initialAppState: IAppState = {
  pictures: [],
  videos: [],
  AppName: "App1",
  AutoUID: generateAutoUid(),
  AppStatus: "Status.NotStarted",
};

export const initialPicture: IPicture = {
  id: undefined,
  attributes: {
    title: `test ${new Date()}`,
    url: "https://images.unsplash.com/photo-1507525428034-b723cf961d3e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8YmVhY2h8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
    tags: "",
    description: "",
    like_num: 0,
    view_num: 0,
  },
};
