import { gql } from "@apollo/client";

export const GET_ALL_PICTURES = gql`
  query getAllPictures(
    $page: Int
    $pageSize: Int
    $filters: PictureFiltersInput
  ) {
    pictures(
      pagination: { page: $page, pageSize: $pageSize }
      sort: "id:desc"
      filters: $filters
    ) {
      data {
        id
        attributes {
          title
          url
          like_num
          view_num
        }
      }
    }
  }
`;


export const GET_ONE_PICTURE = gql`
  query getOnePicture($id: ID!) {
    picture(id: $id) {
      data {
        id
        attributes {
          title
          url
          tags
          description
          like_num
          view_num
        }
      }
    }
  }
`;

export const ADD_PICTURE = gql`
  mutation createPicture($title: String!, $url: String!, $publishedAt: DateTime)
   {
    createPicture(
      data: { 
        publishedAt: $publishedAt
        title: $title
        url: $url
      }
    ) 
    {
      data {
        id
        attributes {
          title
          url
          publishedAt
        }
      }
    }
  }
`;

export const EDIT_PICTURE = gql`
  mutation updatePicture(
    $id: ID!
    $title: String!
    $url: String!
    $tags: String
    $description: String
  ) {
    updatePicture(
      id: $id
      data: { description: $description, title: $title, url: $url, tags: $tags }
    ) {
      data {
        id
        attributes {
          title
          url
          tags
          description
        }
      }
    }
  }
`;



export const DEL_ONE_PICTURE = gql`
  mutation deletePicture($id: ID!) {
    deletePicture(id: $id) {
      data {
        id
        attributes {
          title
          url
          tags
          description
        }
      }
    }
  }
`;


export const GET_AUTO_UID_POINT = gql`
  query getAutoUidPoint($filters: PointFiltersInput) {
    points(filters: $filters) {
      data {
        attributes {
          auto_uid
          point
        }
        id
      }
    }
  }
`;

export const ADD_AUTO_UID_POINT = gql`
  mutation createPoint($auto_uid: String!, $publishedAt: DateTime) {
    createPoint(
      data: { publishedAt: $publishedAt, auto_uid: $auto_uid, point: 1 }
    ) {
      data {
        id
        attributes {
          auto_uid
          point
          publishedAt
        }
      }
    }
  }
`;

export const EDIT_AUTO_UID_POINT = gql`
  mutation updatePoint($id: ID!, $point: Int!, $referral: JSON) {
    updatePoint(id: $id, data: { point: $point, referral: $referral }) {
      data {
        id
        attributes {
          auto_uid
          point
        }
      }
    }
  }
`;