import { useReducer } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom"; // v6
import { AppContext } from "./store";
import { appReducer } from "./store/reducer";
import { initialAppState } from "./store/state";
import { AddPicture } from "./components/AddPicture";
import { ListPictures } from "./components/ListPicture";
import { MyPicture } from "./components/MyPicture";
import { EditPicture } from "./components/EditPicture";
import { loadFromLocalStorage, saveToLocalStorage } from "./store/localStorage";

const localStorageState = loadFromLocalStorage(); // only load once
  
function App() {

  const [contextState, contextDispatch] = useReducer(
    appReducer,
    localStorageState || initialAppState
  );
  
  // persist data to localStorage
  if (contextState !== initialAppState) {
    saveToLocalStorage(contextState);
  }

  // console.log("=== oldState ===", localStorageState);
  console.log("=== newState ===", contextState);

  return (
    <AppContext.Provider value={{ contextState, contextDispatch }}>
      <BrowserRouter>
        <Routes>
          <Route path="/addPicture" element={<AddPicture />} />
          <Route path="/editPicture/:id" element={<EditPicture />} />
          <Route path="/myPicture" element={<MyPicture />} />
          
          <Route path="/UID/:autoUID/PID/:picID" element={<ListPictures />} />
          <Route path="/UID/:autoUID" element={<ListPictures />} />
          <Route path="/PID/:picID" element={<ListPictures />} />
          <Route path="/" element={<ListPictures />} />
        </Routes>
      </BrowserRouter>
    </AppContext.Provider>
  );
}

export default App;
