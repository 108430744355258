import { DAILY_FREE_NUM } from "../constants/settings";
interface IProps {
  allowedNum: number;
  recheckPoint: () => void;
};
export const ReachedLimit = ({ allowedNum, recheckPoint }: IProps): JSX.Element => {
  const reloadPage = () => {
    window.location.href = '/?recheck=' + (new Date);
  }
  return (
    <div className="card">
      <h3>Woops, You've reached your limit today {allowedNum}</h3>
      <br />
      To show more funny pictures, please share one or more of above pictures to
      to other people, then you can continue to view more funny pictures. (Every
      click on your shared link you will able to view {DAILY_FREE_NUM} pictures)
      e.g. You can continue to view {DAILY_FREE_NUM}*5=
      {DAILY_FREE_NUM * 5} funny pictures if 5 people click your link
      <button onClick={reloadPage}>Recheck</button>
    </div>
  );
};
