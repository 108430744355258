import { useEffect, useState } from "react";
import { myLog } from "../helpers/function";

interface IProps {
  loading: boolean;
  hasMoreData: boolean;
  handleLoadMore: () => void;
};

export const ListPictureLoadMore = ({
  loading,
  hasMoreData,
  handleLoadMore,
}: IProps): JSX.Element => {
  const [isFetching, setIsFetching] = useState(false);
  
  // infinite scroll start
  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
      document.documentElement.offsetHeight ||
      isFetching
    ) {
      return;
    }
    if (hasMoreData) {
      setIsFetching(true);
    }
  };
  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, [isFetching]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    if (isFetching && hasMoreData) {
      handleLoadMore();
      setIsFetching(false);
    }
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isFetching]);


  myLog(
    "title:load more",
    "isFetching=" + isFetching,
    "hasMoreData=" + hasMoreData,
    "loading=" + loading,
    "color:yellow"
  );
  
  return (
    <>
      {(loading || isFetching) ? (
        <div>
          <i className="fas fa-spinner fa-spin mr-1"></i> Loading more data ...
        </div>
      ) : (
        <div>
          {hasMoreData ? (
            <button onClick={handleLoadMore}>handleLoadMore</button>
          ) : (
            <span>Have fun!</span>
          )}
        </div>
      )}
    </>
  );
};
