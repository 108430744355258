import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { ADD_PICTURE } from "../constants/graphqlQueries";
import { Link, Navigate } from "react-router-dom";
import { AppContext } from "../store";
import { addOnePicture } from "../store/reducer";
import { IPicture } from "../constants/interface";
import { initialPicture } from "../store/state";
import axios from "axios";
import { IMAGEKIT_AUTH_URL } from "../constants/settings";

export const AddPicture = (): JSX.Element => {
  const { contextDispatch, contextState } = useContext(AppContext);

  const [createPicture, createdPic] = useMutation(ADD_PICTURE);
  const [newPic, setNewPic] = useState<IPicture>(initialPicture);
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [selectedFiles, setSelectedFiles] = useState<FileList>();
  const [previewUrl, setPreviewUrl] = useState<string>();

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputData = {
      [event.target.name]: event.target.value,
    };
    setNewPic({
      id: undefined,
      attributes: { ...newPic.attributes, ...inputData },
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    
    if (!selectedFiles && !newPic.attributes.url.includes('http')) {
      setErrorMessage(
        "Please select a file to upload or enter an URL "
      );
      return false;
    }
    const uploadResult = await upload2imagekit(
      selectedFiles ? selectedFiles[0] : newPic.attributes.url
    );
    if (!uploadResult || !uploadResult.data.url) {
      setErrorMessage("Upload failed!");
      return false;
    }
    const result = await createPicture({
      variables: {
        ...newPic.attributes,
        publishedAt: new Date(),
        url: uploadResult.data.url,
      },
    });

    if (result.data && result.data.createPicture.data) {
      await contextDispatch(addOnePicture(result.data.createPicture.data));
    }

    console.log("handleSubmit createdPic", createdPic);
  };

  const selectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectedFiles(event.target.files);
      setPreviewUrl(URL.createObjectURL(event.target.files[0]));
    }
    console.log("selectedFiles", selectedFiles);
  }

  const upload2imagekit = async (file:File|string) => {
    let newFormData = new FormData();
    setUploadLoading(true);
    const result = await axios.post(IMAGEKIT_AUTH_URL).then((res) => {
      newFormData.append("publicKey", res.data.publicKey);
      newFormData.append("signature", res.data.signature);
      newFormData.append("token", res.data.token);
      newFormData.append("expire", res.data.expire);
      newFormData.append(
        "fileName",
        typeof file === "string" ? newPic.attributes.title : file.name
      );
      newFormData.append("file", file);
      // console.log("imageKitAuth res", res,file);
      return axios.post(res.data.uploadUrl, newFormData);
    }).catch(error => {
      console.log(error);
      setUploadLoading(false);
    });
    setUploadLoading(false);
    console.log("upload2imagekit result", result);
    return result;
  };


  return (
    <div className="row justify-content-md-center">
      {createdPic.data && !createdPic.error && (
        <Navigate to="/?from=createdPic" replace={true} />
      )}

      <div className="col-md-3 d-flex justify-content-center">
        <div className="card mb-4" style={{ width: "300px" }}>
          <form onSubmit={handleSubmit}>
            <div className="form-group mb-2">
              Title:
              <input
                onChange={handleOnChange}
                type="text"
                name="title"
                value={newPic.attributes.title}
              />
            </div>
            <div className="form-group mb-2">
              URL:
              <input
                onChange={handleOnChange}
                type="text"
                name="url"
                value={newPic.attributes.url}
              />
            </div>

            {errorMessage && <div className="text-danger">{errorMessage}</div>}

            {createdPic.error && (
              <div>Submission error! {createdPic.error.message}</div>
            )}

            {createdPic.data && <div>Submission success! </div>}

            <div className="form-group mb-2">
              {(previewUrl || newPic.attributes.url.includes("http")) && (
                <img
                  src={previewUrl || newPic.attributes.url}
                  style={{ width: "300px" }}
                  alt="preview"
                />
              )}
              <input
                type="file"
                className="form-control"
                onChange={selectFile}
              />
            </div>
            {uploadLoading || createdPic.loading ? (
              <button type="button" className="btn btn-secondary mt-2 mb-2">
                <i className="fas fa-spinner fa-spin mr-1"></i> Submitting ...
              </button>
            ) : (
              <button type="submit" className="btn btn-primary mt-2 mb-2">
                Add New
              </button>
            )}
          </form>
        </div>
      </div>

      <div className="col-md-12 text-center mb-5">
        <Link
          to={{
            pathname: "/",
            search: "?from=addPicture",
            hash: "#the-hash",
          }}
        >
          <i className="fas fa-list m-3"></i>Back to list
        </Link>
      </div>
    </div>
  );
};
