import { useMutation } from "@apollo/client";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookMessengerIcon, FacebookMessengerShareButton, FacebookShareButton, PinterestIcon, PinterestShareButton, RedditIcon, RedditShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
import { DEL_ONE_PICTURE } from "../constants/graphqlQueries";
import { WEB_BASE_URL } from "../constants/settings";
import { AppContext } from "../store";
import { delOnePicture } from "../store/reducer";

interface IProps {
  id: string;
};

export const AddNewPicLink = (): JSX.Element => {
  return (
      <Link
        to={{
          pathname: "/addPicture",
          search: "?sort=name",
          hash: "#the-hash",
        }}
      >
        <i className="fas fa-plus-square m-3"></i>Add New
      </Link>
  );
};

export const ListPictureLinks = ({ id }: IProps): JSX.Element => {
  const { contextDispatch, contextState } = useContext(AppContext);

  const [delOnePicMutation, deletedPic] = useMutation(DEL_ONE_PICTURE);

  const handleDeletePic = async (id: string | number) => {
    const result = await delOnePicMutation({ variables: { id: id } });
    if (result && result.data && result.data.deletePicture.data) {
      contextDispatch(delOnePicture(result.data.deletePicture.data));
    }
    console.log("handleDeletePic======", deletedPic, result);
  };

  const shareUrl = WEB_BASE_URL + "/UID/" + contextState.AutoUID + "/PID/" + id;
  const currentPic = contextState.pictures.find(p => p.id === id);

  return (
    <>
      <a href={`#pic${{ id }}`} className="text-black">
        Share:
      </a>
      <FacebookShareButton url={shareUrl} className="m-1">
        <FacebookIcon size={24} round={true} />
      </FacebookShareButton>

      <FacebookMessengerShareButton
        url={shareUrl}
        appId="521270401588372"
        className="m-1"
      >
        <FacebookMessengerIcon size={24} round />
      </FacebookMessengerShareButton>

      <WhatsappShareButton url={shareUrl} separator=":: " className="m-1">
        <WhatsappIcon size={24} round />
      </WhatsappShareButton>

      <TwitterShareButton url={shareUrl} className="m-1">
        <TwitterIcon size={24} round />
      </TwitterShareButton>

      <PinterestShareButton
        url={String(window.location)}
        media={`${String(window.location)}/${shareUrl}`}
        className="m-1"
      >
        <PinterestIcon size={24} round />
      </PinterestShareButton>

      <RedditShareButton
        url={shareUrl}
        windowWidth={660}
        windowHeight={460}
        className="m-1"
      >
        <RedditIcon size={24} round />
      </RedditShareButton>

      <EmailShareButton
        url={shareUrl}
        subject={`Funny Pictures: ${currentPic?.attributes.title}`}
        body="Some of them are so funny, have a look: "
        className="m-1"
      >
        <EmailIcon size={24} round />
      </EmailShareButton>

      <br />

      <a href={`#edit${{ id }}`} className="card-link">
        Give it a better funny name
      </a>
      <Link
        to={{
          pathname: "/editPicture/" + id,
          search: "?pid=" + id,
          hash: "#the-hash",
        }}
        className="text-success"
      >
        <i className="fa-solid fa-pen-to-square m-1"></i>
      </Link>
      <i
        className="fa-solid fa-trash-can m-1"
        role="button"
        onClick={() => handleDeletePic(id)}
      ></i>
    </>
  );
};
