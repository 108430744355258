import React, { createContext } from "react";
import { AppActions } from './actions';
import { initialAppState } from './state';
import { IAppState } from "./../constants/interface";


export const AppContext = createContext<{
  contextState: IAppState;
  contextDispatch: React.Dispatch<AppActions>;
}>({
  contextState: initialAppState,
  contextDispatch: () => undefined,
});