import { useQuery } from "@apollo/client";
import { useContext } from "react";
import { GET_ALL_PICTURES } from "../constants/graphqlQueries";
import { AppContext } from "../store";
import { addNewPictures } from "../store/reducer";
import ListPictureCard from "./ListPictureCard";

interface IProps {
  picId: string;
};

export const ListPictureSingle = ({ picId }: IProps): JSX.Element => {
  const { contextDispatch, contextState } = useContext(AppContext);
  const singlePic = useQuery(GET_ALL_PICTURES, {
    fetchPolicy: "cache-first",
    variables: { filters: { id: { eq: picId } } },
    onCompleted(res) {
      contextDispatch(addNewPictures(res.pictures.data));
    },
  });

  let pic = contextState.pictures.find(p => p.id === picId); // load from localStorage first

  if (singlePic.called && singlePic.data && singlePic.data.pictures.data.length > 0) {
    pic = singlePic.data.pictures.data[0];
  }

  return pic ? <ListPictureCard pic={pic} /> : <></>;
};
