// Note: The environment variables are embedded during the build time. You must create custom environment variables beginning with REACT_APP_. Any other variables except NODE_ENV will be ignored to avoid accidentally exposing a private key on the machine that could have the same name. Changing any environment variables will require you to restart the development server if it is running.

export const GRAPHQL_URI =
  process.env.REACT_APP_GRAPHQL_URI ||
  "http://local.petstory.online:1447/graphql";

// export const IMAGEKIT_AUTH_URL = process.env.IMAGEKIT_AUTH_URL || "http://localhost:5002/imageKitAuth";
export const IMAGEKIT_AUTH_URL =
  process.env.REACT_APP_IMAGEKIT_AUTH_URL ||
  "https://cloudinary-imagekit-auth.netlify.app/.netlify/functions/index/imageKitAuth";

export const CLOUDINARY_AUTH_URL =
  process.env.REACT_APP_CLOUDINARY_AUTH_URL ||
  "https://cloudinary-imagekit-auth.netlify.app/.netlify/functions/index/cloudinaryAuth";

export const LIST_PICTURE_PAGE_SIZE = 3;

export const WEB_BASE_URL =
  process.env.REACT_APP_WEB_BASE_URL ||
  window.location.protocol + "//" + window.location.host;

export const FIRST_DAY_FREE_NUM = 8;  
export const DAILY_FREE_NUM = 4;  
export const AWARD_NUM_PER_POINT = 5;